<template>
  <base-selector
    :items="items"
    label="Task type"
    :initial-value="initialValue"
    @select="newSelect => $emit('select', newSelect)"
  />
</template>

<script>
  import BaseSelector from '@/components/base/selectors/BaseSelector';
  import taskTypes from '@/static';
  export default {
    name: 'BaseTaskTypeSelector',
    components: { BaseSelector },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        items: taskTypes,
      };
    },
  };
</script>

<style scoped>

</style>
