const types = [
  {
    label: 'Task type',
    value: '',
  },
  {
    label: 'li_record_video',
    value: 'li_record_video',
    data: {},
  },

  {
    label: 'two_factor_auth',
    value: 'two_factor_auth',
    data: {},
  },
  {
    label: 'set_cookies',
    value: 'set_cookies',
    data: {},
  },
  {
    label: 'cookie_warming_up',
    value: 'cookie_warming_up',
    data: {},
  },
  {
    label: 'li_record_video',
    value: 'li_record_video',
    data: {},
  },
  {
    label: 'li_fetch_ssi',
    value: 'li_fetch_ssi',
    data: {},
  },

  {
    label: 'li_auth_auto',
    value: 'li_auth_auto',
    data: {
      username: 'ersahin70@hotmail.com',
      password: '2129401',
      auth_link: '',
    },
  },
  {
    label: 'li_accept_invites',
    value: 'li_accept_invites',
    data: {},
  },
  {
    label: 'li_fetch_me',
    value: 'li_fetch_me',
    data: {
    },
  },
  {
    label: 'li_fetch_me_sales',
    value: 'li_fetch_me_sales',
    data: {

    },
  },
  {
    label: 'li_clear_connections',
    value: 'li_clear_connections',
    data: {
      max_invites_count: 900,
      profiles: [],
      more: false,
    },
  },
  {
    label: 'li_fetch_connections',
    value: 'li_fetch_connections',
    data: {
      stop_profile_links: [
        'https://www.linkedin.com/in/kathy-casatelli-32605536/',
        'https://www.linkedin.com/in/skye-kaur-50884220a/',
        'https://www.linkedin.com/in/maisie-potts-05602620b/',
        'https://www.linkedin.com/in/alex-andreev-62ab64226/',
      ],

    },
  },
  {
    label: 'li_fetch_dialogs',
    value: 'li_fetch_dialogs',
    data: {
      mode: 'basic',

      new_only: false,
      max_dialogs: 25,
      mark_unread: true,
      self_link: 'https://www.linkedin.com/in/renae-furlow-809a5b4/',
      stop_dates: [
        'Mar 24',
        'Mar 24',
        'Mar 24',
        'Mar 24',
        'Mar 24',
        'Mar 23',
        'Mar 22',
        'Mar 21',
        'Mar 20',
        'Mar 19',
      ],
      profiles: [],

    },
  },
  {
    label: 'li_make_search',
    value: 'li_make_search',
    data: {
      search_id: '623d81b19044015d451b62de',
      page: 25,
      mode: 'sales_navigator',
      search_link: 'https://www.linkedin.com/sales/search/people?query=(recentSearchParam%3A(id%3A1334441289%2CdoLogHistory%3Atrue)%2Cfilters%3AList((type%3ATITLE%2Cvalues%3AList((id%3A1%2Ctext%3AOwner%2CselectionType%3AINCLUDED)%2C(id%3A8%2Ctext%3AChief%2520Executive%2520Officer%2CselectionType%3AINCLUDED)%2C(id%3A8136%2Ctext%3AGroup%2520Chief%2520Executive%2520Officer%2CselectionType%3AINCLUDED)%2C(text%3ACEO%2CselectionType%3AINCLUDED)%2C(text%3AMD%2CselectionType%3AINCLUDED)%2C(id%3A16%2Ctext%3AManaging%2520Director%2CselectionType%3AINCLUDED))%2CselectedSubFilter%3ACURRENT)%2C(type%3AINDUSTRY%2Cvalues%3AList((id%3A43%2Ctext%3AFinancial%2520Services%2CselectionType%3AINCLUDED)))%2C(type%3AREGION%2Cvalues%3AList((id%3A102299470%2Ctext%3AEngland%252C%2520United%2520Kingdom%2CselectionType%3AINCLUDED)))%2C(type%3ACOMPANY_HEADCOUNT%2Cvalues%3AList((id%3AC%2Ctext%3A11-50%2CselectionType%3AINCLUDED)%2C(id%3AD%2Ctext%3A51-200%2CselectionType%3AINCLUDED)))%2C(type%3ALEAD_HIGHLIGHTS%2Cvalues%3AList((id%3ARPOL%2Ctext%3APosted%2520on%2520linkedin%2520in%252030%2520days%2CselectionType%3AINCLUDED)))))&sessionId=s2Xk8K8sS3i4dn4U92qFKw%3D%3D&viewAllFilters=true&page=25',

      profiles: [
      ],
      salesNavigatorNewLink: '',
    },
  },
  {
    label: 'li_make_search_api',
    value: 'li_make_search_api',
    data: {
      search_id: '623d81b19044015d451b62de',
      page: 25,
      mode: 'sales_navigator',
      search_link: 'https://www.linkedin.com/sales/search/people?query=(recentSearchParam%3A(id%3A1334441289%2CdoLogHistory%3Atrue)%2Cfilters%3AList((type%3ATITLE%2Cvalues%3AList((id%3A1%2Ctext%3AOwner%2CselectionType%3AINCLUDED)%2C(id%3A8%2Ctext%3AChief%2520Executive%2520Officer%2CselectionType%3AINCLUDED)%2C(id%3A8136%2Ctext%3AGroup%2520Chief%2520Executive%2520Officer%2CselectionType%3AINCLUDED)%2C(text%3ACEO%2CselectionType%3AINCLUDED)%2C(text%3AMD%2CselectionType%3AINCLUDED)%2C(id%3A16%2Ctext%3AManaging%2520Director%2CselectionType%3AINCLUDED))%2CselectedSubFilter%3ACURRENT)%2C(type%3AINDUSTRY%2Cvalues%3AList((id%3A43%2Ctext%3AFinancial%2520Services%2CselectionType%3AINCLUDED)))%2C(type%3AREGION%2Cvalues%3AList((id%3A102299470%2Ctext%3AEngland%252C%2520United%2520Kingdom%2CselectionType%3AINCLUDED)))%2C(type%3ACOMPANY_HEADCOUNT%2Cvalues%3AList((id%3AC%2Ctext%3A11-50%2CselectionType%3AINCLUDED)%2C(id%3AD%2Ctext%3A51-200%2CselectionType%3AINCLUDED)))%2C(type%3ALEAD_HIGHLIGHTS%2Cvalues%3AList((id%3ARPOL%2Ctext%3APosted%2520on%2520linkedin%2520in%252030%2520days%2CselectionType%3AINCLUDED)))))&sessionId=s2Xk8K8sS3i4dn4U92qFKw%3D%3D&viewAllFilters=true&page=25',

      profiles: [
      ],
      salesNavigatorNewLink: '',
    },
  },
  {
    label: 'li_logout',
    value: 'li_logout',
    data: {},
  },
  {
    label: 'li_invite_event',
    value: 'li_invite_event',
    data: {
      profiles: [
        {
          profile_id: '64d32448e31b92d0688009af',
          profile_link: 'https://www.linkedin.com/in/sicinc',
          original_name: 'Deval Shah',
        },
        {
          profile_id: '64d32448e31b92d0688009b0',
          profile_link: 'https://www.linkedin.com/in/sandeep2604',
          original_name: 'Sandeep Aggarwal MBA',
        },
        {
          profile_id: '64d32448e31b92d0688009b1',
          profile_link: 'https://www.linkedin.com/in/srinivasreddy-chinnam-35b4b024',
          original_name: 'Srinivas Reddy Chinnam',
        },
        {
          profile_id: '64d32448e31b92d0688009b2',
          profile_link: 'https://www.linkedin.com/in/udaywagle',
          original_name: 'Uday Wagle',
        },
      ],
      sequence_id: '64d0fbab483c7a9b167665a5',
      event_link: 'https://www.linkedin.com/events/constructionsafety-bestpractice7072154115014864896/',
    },
  },
  {
    label: 'li_send_message',
    value: 'li_send_message',
    data: {
      text: "Thanks for connecting, James.\n\nIf you’ve been working or have worked for British Steel, you might be due pension compensation.\n\nThe average compensation is considerable, defined benefit pensions shouldn’t have been moved by IFAs giving bad advice. \n\nEven if you're happy with your decision to transfer, you should consider making a claim. If you don’t, you may end up with less money in retirement than you should have done. FSCS may be able to compensate you up to £85,000\n\nI know. Sounds too good to be true, right?\n\nBut we’ve already helped clients get back over - millions of pounds worth of claims.\n\nThe basic premise is that our clients receive compensation for being Mis-sold by moving away from their defined benefits pension. \n\nNearly every British steel worker who moved their defined benefit was mis sold.\n\nPension Compensation is black and white and you are well within your rights to compensation. It really is worth your while for us to chase this up.\n\nIf you’d like a free consultation please visit www.counselclaims.co.uk\n\n\nCheers,\n\nYasmin",
      inbox: 'inbox',
      profile_id: '62264711033045a8b8916de9',
      profile_link: 'https://www.linkedin.com/in/jamesoliver125',
      self_link: 'https://www.linkedin.com/in/alex-andreev-62ab64226',
      self_linkedin_id: 'ACoAADjEqIIBPkuGrrA-vGNGdnQZ4CzbDSOZrB4',
      original_name: 'Michael Shendelev',
      img_id: 'D4D03AQHEhnsauuT70A',
      linkedin_ids: [
        'ACoAADug4vUBYt61pfgxeN5L7klQ-Jh0Z9rB7xk',
      ],
    },
  },
  {
    label: 'li_send_comment',
    value: 'li_send_comment',
    data: {},
  },

  {
    label: 'li_connect_profile',
    value: 'li_connect_profile',
    data: {
      profile_id: '61bb66ff792cdfc937475444',
      connection_text: 'Hello Jessie, I was doing some research on Medical Solutions.  I believe you are running Microsoft Dynamics internally.  That is why I think we should connect.',
      profile_link: 'https://www.linkedin.com/in/jessie-blackman-pmp-csm-8280126',
    },
  },
  {
    label: 'li_connect_profile_api_bulk',
    value: 'li_connect_profile_api_bulk',
  },
  {
    label: 'li_send_group_message',
    value: 'li_send_group_message',
  },
  {
    label: 'li_make_group_search',
    value: 'li_make_group_search',
  },
  {
    label: 'li_make_group_search_api',
    value: 'li_make_group_search_api',
  },
  {
    label: 'li_set_phone',
    value: 'li_set_phone',
  },
  {
    label: 'li_connect_profile_api',
    value: 'li_connect_profile_api',
    data: {
      profile_id: '60e20cfd1f5ea3870aee848b',
      linkedin_id: 'ACwAACO8ONEBVBtRcA3e1v-SY9RaWnOFXoYfnkA',
      connection_text: 'Al - I came across your profile and would love to connect! I am trying to expand my network of professionals in your space. Would you be open to connecting?',
      link: 'https://www.linkedin.com/in/al-iacobelli-95594a148',
    },
  },
  {
    label: 'li_fetch_after_connect',
    value: 'li_fetch_after_connect',
    data: {
      profile_id: '621e6229747f50d980f1b428',
      profile_link: 'https://www.linkedin.com/in/priyankajha',
    },
  },
  {
    label: 'li_fetch_profile',
    value: 'li_fetch_profile',
    data: {
      profile_id: '621e6229747f50d980f1b428',
      profile_link: 'https://www.linkedin.com/in/priyankajha',
    },
  },
  {
    label: 'li_fetch_profile_api_html',
    value: 'li_fetch_profile_api_html',
    data: {
      profile_id: '623cd7d67ee86abd24258c99',
      linkedin_id: 'ACwAAAAvBboB5dxmwXZQ0KDSZADPm-WaT8Hqd5A',
      public_id: 'mannylopezsbba',
    },
  },
  // {
  //   label: 'li_convert_recruiter_link',
  //   value: 'li_convert_recruiter_link',
  //   data: {},
  // },
  {
    label: 'li_enter_security_code',
    value: 'li_enter_security_code',
    data: {
      resend: true,
      security_code: '000000',
    },
  },
  {
    label: 'li_set_proxy',
    value: 'li_set_proxy',
    data: {
      _id: '61acf4bd8111691007e4e1cf',
      status: 'active',
      provider: 'webshare',
      is_used: true,
      usage_in_integrations: 0,
      created_at: '2021-12-05 17:19:57',
      updated_at: '2021-12-05 17:19:57',
      username: 'xrkhxfma',
      password: 'l7s2boxv05yj',
      proxy_address: '185.102.48.28',
      ports: {
        http: 6110,
        socks5: 6110,
      },
      valid: true,
      country_code: 'DE',
      country_code_confidence: 0.8,
      external_id: null,
      credentials: '61a90db02c53771561ea16a7',
    },
  },
  {
    label: 'li_send_inmail',
    value: 'li_send_inmail',
    data: {
      profile_link: 'https://www.linkedin.com/in/nicholas-martin-ndgm',
      profile_id: '6228a4b93f9dfe88acfd3c15',
      text: "Hi Nicholas, I'm reaching out to company directors to see if they are aware that their business could pay for your life insurance, and thought it could be of interest to you?\nThere would be NO benefit-in-kind Tax to pay, and you can claim corporation tax relief on the premiums!\nWhy not let your business protect your family Tax free??!!\nIf you'd like me to provide you with a quote then please let me know or you can provide your details on the link below - https://b2bfinance.com/insurance/relevant-life/",
      subject: 'Mainframe Business & IT Consulting',

    },

  },
  {
    label: 'li_send_sales_inmail_api',
    value: 'li_send_sales_inmail_api',

    data: {
      link: 'https://www.linkedin.com/sales/people/ACoAAAVATAUBQl6SYXrDnopicA97lcUMM3IRtdc,',
      profile_id: '6228a4b93f9dfe88acfd3c15',
      text: "Hi Nicholas, I'm reaching out to company directors to see if they are aware that their business could pay for your life insurance, and thought it could be of interest to you?\nThere would be NO benefit-in-kind Tax to pay, and you can claim corporation tax relief on the premiums!\nWhy not let your business protect your family Tax free??!!\nIf you'd like me to provide you with a quote then please let me know or you can provide your details on the link below - https://b2bfinance.com/insurance/relevant-life/",
      subject: 'Mainframe Business & IT Consulting',
      entity_urn: 'urn:li:fsd_profile:ACoAADPDD2EBtcGZYn_uFMQD5UatDPuRCJDWedM',
      linkedin_id: 'ACoAAAVATAUBQl6SYXrDnopicA97lcUMM3IRtdc',
      actual_link: 'https://www.linkedin.com/in/nicholas-martin-ndgm/',
    },
  },

  {
    label: 'li_send_inmail_api',
    value: 'li_send_inmail_api',
    data: {
      profile_link: 'https://www.linkedin.com/in/nicholas-martin-ndgm',
      profile_id: '6228a4b93f9dfe88acfd3c15',
      text: "Hi Nicholas, I'm reaching out to company directors to see if they are aware that their business could pay for your life insurance, and thought it could be of interest to you?\nThere would be NO benefit-in-kind Tax to pay, and you can claim corporation tax relief on the premiums!\nWhy not let your business protect your family Tax free??!!\nIf you'd like me to provide you with a quote then please let me know or you can provide your details on the link below - https://b2bfinance.com/insurance/relevant-life/",
      subject: 'Mainframe Business & IT Consulting',
      entity_urn: 'urn:li:fsd_profile:ACoAADPDD2EBtcGZYn_uFMQD5UatDPuRCJDWedM',

      actual_link: 'https://www.linkedin.com/in/nicholas-martin-ndgm/',
    },
  },
  {
    label: 'li_send_sales_inmail',
    value: 'li_send_sales_inmail',
    data: {
      profile_id: '62345253d27b4d445d0a3dd1',
      text: "Hi Saumitra, I'm reaching out to company directors to see if they are aware that their business could pay for your life insurance, and thought it could be of interest to you? There would be NO benefit-in-kind Tax to pay, and you can claim corporation tax relief on the premiums!",
      subject: 'SKAMAT UK LIMITED',
      sales_link: 'https://www.linkedin.com/sales/people/ACwAAAAt-yQBOYYfgQ3CGELm8XZZoktgW9GhLh0,',
      planned_for: '2022-03-25 11:42:56',
    },
  },
  {
    label: 'li_fetch_post',
    value: 'li_fetch_post',
    data: {
      post_link: 'https://www.linkedin.com/posts/zoominfo_zoominfo-customer-story-esker-activity-6907332855618539521--xAd',
      search_id: '6231c615e0227a9720d139a8',
      max_likes: 1000,
      liked_by: [
        {
          full_no_split_name: 'Shauna Marhafer, MBA',
          first_name: 'Shauna',
          last_name: 'Marhafer',
          full_name: 'Shauna Marhafer',
          link: 'https://www.linkedin.com/in/shauna-marhafer-mba-5839a15',
          linkedin_id: 'ACoAAAEJL_MB4QSe9kw6fxpZ0_iYMmkaYQdjKu4',
          company_name: null,
          university_name: null,
          position: 'Senior Director, Marketing',
          headline: null,
          location: null,
          photo_link: 'https://media-exp1.licdn.com/dms/image/C4E03AQFHtJEaVHHU9A/profile-displayphoto-shrink_100_100/0/1637090554114?e=1652918400&v=beta&t=7Wc55sSqKdv-TtaZuaeO6Ppv78LK5HeZxD4xF1AXxFo',
          is_premium: false,
          is_request_sent: false,
          is_connected: false,
          workflow_is_changed: false,
        },
      ],

      sent_by_time: true,
    },
  },
  {
    label: 'li_fetch_navigator_list',
    value: 'li_fetch_navigator_list',
    data: {
      search_id: '62318d1dbfdabdb7cc4ce044',
      page: 2,
      mode: 'sales_navigator',
      link: 'https://www.linkedin.com/sales/lists/people/6867807483839221760?sortCriteria=CREATED_TIME&sortOrder=DESCENDING&page=2',
      profiles: [],
    },
  },
  {
    label: 'li_fetch_navigator_list_api',
    value: 'li_fetch_navigator_list_api',
    data: {
      search_id: '62318d1dbfdabdb7cc4ce044',
      page: 2,
      mode: 'sales_navigator',
      link: 'https://www.linkedin.com/sales/lists/people/6867807483839221760?sortCriteria=CREATED_TIME&sortOrder=DESCENDING&page=2',
      profiles: [],
    },
  },
  {
    label: 'li_endorse_skills',
    value: 'li_endorse_skills',
    data: {
      trigger: 'sequence_perofm_action',
      profile_id: '6215645392e610e5a877c0ac',
      profile_link: 'https://www.linkedin.com/in/arnorobbertse',
      count: 3,

      actual_link: 'https://www.linkedin.com/in/arnorobbertse/',
    },
  },
  // {
  //   label: 'li_fetch_company',
  //   value: 'li_fetch_company',
  //   data: {},
  // },
  {
    label: 'li_fetch_profile_dialog',
    value: 'li_fetch_profile_dialog',
    data: {
      profile_id: '62344f49dc460cd67549542e',
      profile_link: 'https://www.linkedin.com/in/jeanpaulsartre',
      for_task: '623dac9788e0df585a5dd485',
      trigger: 'li_send_message',

      actual_link: 'https://www.linkedin.com/in/jeanpaulsartre/',
      messages: [],
      person: {
        link: null,
        inmail_status: 'accepted',
      },
    },
  },
  {
    label: 'li_like_post',
    value: 'li_like_post',
    data: {
      post_link: 'https://www.linkedin.com/feed/update/urn:li:activity:6883407187117441024/',

    },
  },
  {
    label: 'li_follow_profile',
    value: 'li_follow_profile',
    data: {
      trigger: 'sequence_perofm_action',
      profile_id: '621e18b3c70082ed35b53d74',
      profile_link: 'https://www.linkedin.com/in/tolyk',

      actual_link: 'https://www.linkedin.com/in/tolyk/',
    },

  },
  {
    label: 'li_fetch_recruiter_project',
    value: 'li_fetch_recruiter_project',
    data: {},
  },
  {
    label: 'li_fetch_posts',
    value: 'li_fetch_posts',
    data: {
      profile_link: 'https://www.linkedin.com/in/caseyplachek',
      profile_id: '623510885f6b28cd1b626755',
      profile_posts_link: 'https://www.linkedin.com/in/caseyplachek/detail/recent-activity/shares/',

      posts: [
        {
          link: 'https://www.linkedin.com/feed/update/urn:li:activity:6909629107471310848/',
        },
      ],
    },
  },
  {
    label: 'li_join_event',
    value: 'li_join_event',
    data: {
      link: 'https://www.linkedin.com/events/6889293281075720192/',
      search_id: '6238c58cd9ba8aaf4427a065',

      start_date: 'Tue, Mar 22, 2022, 6:00 PM',
      end_date: '6:45 PM',
      search_link: '',
      name_of_event: 'Four Ways Packaging Manufacturers Use IIoT to Solve Their Biggest Challenge',
      errorBlock: false,
      successBlock: false,
      cardIsloaded: true,
      timezone: -180,
      is_ended: true,
    },
  },
  {
    label: 'li_join_group',
    value: 'li_join_group',
  },
  {
    label: 'li_send_event_message',
    value: 'li_send_event_message',
    data: {
      profiles: [
        {
          profile_id: '623da5f2ea61af2c54598419',
          profile_link: 'https://www.linkedin.com/in/jawad-shah-218093155',
          message: "Hey, Jawad, I see you're attending the LinkedIn business growth event hosted by JV Genius and thought I would shoot you a message. \n\nThis event falls right in-line with what Buzz.ai does. Buzz is a sales enablement platform that allows teams and individuals to generate, manage, and communicate prospects into leads. I hate to sound pitchy through socials but would love the opportunity to jump on a call with you to discuss your current techniques and future growth goals further. \n\nAre you available for a 30 minute chat this week?",
        },
      ],
      search_link: 'https://www.linkedin.com/search/results/people/?eventAttending=%5B%226854120000677060608%22%5D&origin=EVENT_PAGE_CANNED_SEARCH&page=47',

      messages_sent: 9,
      profiles_responses: [
        {
          profile_link: 'https://www.linkedin.com/in/jawad-shah-218093155',
          profile_id: '623da5f2ea61af2c54598419',
          status: 'done',
          error: '',
          result: 'success',
        },
      ],
    },
  },

];
types.sort((a, b) => a.label > b.label ? 1 : -1);
export default types;
