<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline red white--text lighten-2">
          ыыыы
        </v-card-title>

        <v-card-text>
          <h3 class="mt-10">
            Вы точно хотите создать такую задачу?
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="createTaskMethod"
          >
            Создать задачу
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="3">
      <base-credentials-selector
        @select="selectCredential"
      />
    </v-col>
    <v-col cols="3">
      <base-task-type-selector
        @select="selectType"
      />
    </v-col>
    <v-col cols="3" />
    <v-col cols="1">
      <v-btn
        depressed
        color="primary"
        @click="dialog = true"
      >
        Send task
      </v-btn>
    </v-col>
    <v-col cols="8">
      <h3>Enter task data object: </h3>
      <prism-editor
        v-model="taskData"
        :line-numbers="true"
        :highlight="highlighter"
        class="my-editor"
      />
    </v-col>
  </v-row>
</template>

<script>
  import BaseCredentialsSelector from '@/components/base/selectors/BaseCredentialsSelector';
  import BaseTaskTypeSelector from '@/components/base/selectors/BaseTaskTypeSelector';

  import { createTask } from '@/api/tasksMethods';

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';
  import { PrismEditor } from 'vue-prism-editor'; // import syntax highlighting styles
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  export default {
    name: 'CreateTaskPage',
    components: {
      BaseCredentialsSelector,
      BaseTaskTypeSelector,
      PrismEditor,
    },
    data () {
      return {
        taskData: `const data = {
}
return data`,
        credentials: '',
        taskType: [],
        dialog: false,
      };
    },
    watch: {
      taskType () {
        this.taskData = JSON.stringify(
          this.taskType.data,
          null,
          '\t'
        ).replace(/"([^"]+)":/g, '$1:');
      },
    },
    methods: {
      selectCredential (newCred) {
        this.credentials = newCred;
      },
      selectType (newType) {
        this.taskType = newType;
      },
      highlighter (code) {
        return highlight(code, languages.js); // returns html
      },
      async createTaskMethod () {
        this.dialog = false;
        // eslint-disable-next-line no-eval
        const result = eval('(() => {' + 'return' + this.taskData + '})()');
        await createTask(this.credentials._id, this.taskType.value, result);
      },
    },
  };
</script>

<style>

.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 25px;
}

   .prism-editor__textarea:focus {
     outline: none;
   }

   .height-300 {
     height: 300px;
   }
</style>
